$(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 100) {
        $(".top1").addClass("top1-scrolled");
    }
    else{
      $(".top1").removeClass("top1-scrolled");
    }
});
$("#topbtn-toggle").click((function(){$(this).toggleClass("open"),$("#circlemenu").toggleClass("openmenubox")}));
function scrollTo(hash) {
location.hash = "#" + hash;}

var hash = location.hash.replace(/^#/, '');   
	if (hash) {
		$('.nav-tabs a[href="#' + hash + '"]').tab('show');
	} 
	
	$('.nav-links').on('shown.bs.tab', function (e) {
		window.location.hash = e.target.hash;
	})
  $(document).ready((function(){$(".formpopup .effect-16").val(""),$(".input-effect input, .input-effect textarea ").focusout((function(){""!=$(this).val()?$(this).addClass("has-content"):$(this).removeClass("has-content")}))}));

  $(".featuretop").on({
    mouseenter: function () {
      $(".menubox.pricingmenubox").removeClass('showmenu');
      $(".menubox.contactmenubox").removeClass('showmenu');
        $(".menubox.featuresmenubox ").addClass('showmenu');
    },
});
$(".menubox.featuresmenubox").on({
  mouseleave: function () {
        $(".menubox.featuresmenubox ").removeClass('showmenu');
    },
});
$(".pricetop").on({
    mouseenter: function () {
      $(".menubox.featuresmenubox ").removeClass('showmenu');
      $(".menubox.contactmenubox").removeClass('showmenu');
        $(".menubox.pricingmenubox").addClass('showmenu');
    },
});
$(".menubox.pricingmenubox").on({
  mouseleave: function () {
        $(".menubox.pricingmenubox").removeClass('showmenu');
    },
});
$(".contacttop").on({
    mouseenter: function () {
      $(".menubox.featuresmenubox ").removeClass('showmenu');
      $(".menubox.pricingmenubox").removeClass('showmenu');
      $(".menubox.contactmenubox").addClass('showmenu');
    },
});

$(".menubox.contactmenubox").on({
  mouseleave: function () {
        $(".menubox.contactmenubox").removeClass('showmenu');
    },
});
$("#btnraise, #chatboxopen ").on('click', function(){
  $(".menubox.contactmenubox").removeClass('showmenu');
});
$("#topbtn-toggle").on({
    mouseenter: function () {
      $(".menubox.featuresmenubox ").removeClass('showmenu');
      $(".menubox.pricingmenubox").removeClass('showmenu');
      $(".menubox.contactmenubox").removeClass('showmenu');
    },
});

$('.expandbtn').on('click', function(e){
  e.preventDefault();
$('.expandbox').toggleClass('expanded');
$(this).toggleClass('btnexpanded');  
});

$('#solvebox').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
     autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    focusOnSelect: true,
    dots:false,
    arrows: true,
    prevArrow: $('.scrollprev'),
    nextArrow: $('.scrollnext'),
    centerPadding: '0px',
    responsive: [
        {
            breakpoint: 992,
            settings: {
              autoplay: false,
                arrows: true,
     prevArrow: $('.scrollprev'),
    nextArrow: $('.scrollnext'),
               centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
                arrows: true,
    prevArrow: $('.scrollprev'),
    nextArrow: $('.scrollnext'),
               centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
  }); 

 

  var quotes = $(".animatetyped");
  var quoteIndex = -1;
  
  function showNextQuote() {
      ++quoteIndex;
      quotes.eq(quoteIndex % quotes.length)
          .fadeIn(1000)
          .delay(2000)
          .fadeOut(1000, showNextQuote);
  }
  
  showNextQuote();

  var quotes2 = $(".animatetyped2");
  var quoteIndex2 = -1;
  
  function showNextQuotes() {
      ++quoteIndex2;
      quotes2.eq(quoteIndex2 % quotes2.length)
          .fadeIn(1000)
          .delay(2000)
          .css('transform', 'rotateX(90deg)')
          .fadeOut(1000, showNextQuotes);
  }
  
  showNextQuotes();


   